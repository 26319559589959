$(document).ready(function() {
    let options = {
        title: '&#x1F36A; Accepter les Cookies & la Politique de Confidentialit&eacute;?',
        message: 'Nous utilisons les cookies afin de fournir les services et fonctionnalités proposés sur notre site et afin d’améliorer l’expérience de nos utilisateurs.<br/> '
            + 'Les cookies sont des données qui sont téléchargés ou stockés sur votre ordinateur ou sur tout autre appareil.'
            + 'En cliquant sur ”J’accepte”, vous acceptez l’utilisation des cookies. Vous pourrez toujours les désactiver ultérieurement.'
            + 'Si vous supprimez ou désactivez nos cookies, vous pourriez rencontrer des interruptions ou des problèmes d’accès au site.',
        delay: 600,
        expires: 1,
        link: '/vieprivee',
        onAccept: function(){
            var myPreferences = $.fn.ihavecookies.cookie();
            console.log('Yay! The following preferences were saved...');
            console.log(myPreferences);
        },
        uncheckBoxes: true,
        acceptBtnLabel: 'Accepter les Cookies',
        advancedBtnLabel: 'Personnaliser',
        moreInfoLabel: 'Plus d\'informations',
        cookieTypesTitle: 'Selectionner les Cookies que vous voulez accepter',
        fixedCookieTypeLabel: 'Essentiels',
        fixedCookieTypeDesc: 'Ces Cookies sont essentiels au bon fonctionnement du site.',
        cookieTypes: [
            {
                type: 'Site Pr&eacute;f&eacute;rences',
                value: 'preferences',
                description: 'Ces cookies sont utilis&eacute;s pour concerver vos pr&eacute;ferences sur le site, par exemples: couleurs personnalis&eacute;es, format d\'affichage, ...'
            },
            {
                type: 'Analytics',
                value: 'analytics',
                description: 'Cookies relatifs aux visites du site, type de navigateur, etc.'
            },
            {
                type: 'Marketing',
                value: 'marketing',
                description: 'Cookies relatifs au marketing, exemples: newsletters, social media, etc'
            }
        ],
    }

    $('body').ihavecookies(options);
});
