/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import '../css/public.scss';

// require jQuery normally
const $ = require('jquery');
global.$ = global.jQuery = $;

import '../public/js/bootstrap.min.js';
import 'slick-carousel';

import 'swiper';
import '../public/js/jquery.rd-navbar.min';
import '../public/js/device.min';
import '../public/js/slide-exception-fix';
import '../public/js/common';
import '../public/js/script';
import 'jquery-validation';
import '../public/fancybox/dist/jquery.fancybox.min';
import '../public/js/jquery.ihavecookies.min';
import '../public/js/cookies';